
function getDefaultConnectionObj() {
    return {
        company: {},
        companyId: '',
        connectedCompany: {},
        connectedCompanyId: '',
        connections: [],
        storageLocations: {},
        isActive: 'true',
        notes: '',
        others: '',
        dateCreated: null,
        createdBy: '',
        dateUpdated: null,
        updatedBy: '',
    };
}

export const ConnectionUtil = {
    getDefaultConnectionObj
}