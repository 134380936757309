<template>
	<b-modal id="activate-connection" :title="modalTitle" ok-title="Save" ref="modal" @hide="handleClose" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirm-message">
			Are you sure you want to
			<b>{{ initialIsActive === 'true' ? 'deactivate' : 'activate' }}</b>
			this connection from <b>"{{ fromCompanyName }}"</b> to
			<b>"{{ toCompanyName }}"</b>?
		</div>
	</b-modal>
</template>

<script>
// Utils
import { LocationUtil } from '@/utils/locationUtil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'activate-connection',
	components: {
		Loading,
	},
	data() {
		return {
			selConnection: {},
			isActive: true,
			initialIsActive: true,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		fromCompanyName() {
			if (!_.isEmpty(this.selConnection)) {
				return this.selConnection.company.name;
			}
			return '';
		},
		toCompanyName() {
			if (!_.isEmpty(this.selConnection)) {
				return this.selConnection.connectedCompany.name;
			}
			return '';
		},
		modalTitle() {
			return this.initialIsActive === 'true' ? 'Deactivate Connection' : 'Activate Connection';
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelConnection', (selConnection) => {
			this.selConnection = selConnection;
			this.isActive = selConnection.isActive;
			this.initialIsActive = selConnection.isActive; // Update initialIsActive when connection is updated
		});
	},
	methods: {
		toggleStatus(isActive) {
			let status = isActive;
			if (status === 'true') {
				status = 'false';
			} else {
				status = 'true';
			}
			return status;
		},
		resetStatus() {
			this.selConnection.isActive = this.isActive;
			this.$store.commit('SET_CURR_CONNECTION', this.selConnection);
		},
		handleClose() {
			this.resetStatus();
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			if (this.selConnection.company.isActive !== 'true') {
				let companyName = this.selConnection.company.name;
				this.$toaster.warning(
					`The company ${companyName} is currenlty inactive. You cannot activate this connection.`
				);
				return;
			}

			await this.handleSubmit();
		},

		async handleSubmit() {
			try {
				// show loading indicator
				this.isLoading = true;

				// update the status
				this.isActive = this.toggleStatus(this.isActive);
				this.selConnection.isActive = this.isActive;
				this.selConnection.company.geoaddress = LocationUtil.getGeoaddress(this.selConnection.company.geoaddress);
				this.selConnection.connectedCompany.geoaddress = LocationUtil.getGeoaddress(this.selConnection.connectedCompany.geoaddress);

				_.forEach(this.selConnection.storageLocations, (storageLocation) => {
					storageLocation.geoaddress = LocationUtil.getGeoaddress(storageLocation.geoaddress);
				});

				const result = await this.$store.dispatch('updateConnection', this.selConnection);

				if (result.isSuccess) {
					let successMsg = '';
					if (result.data.isActive === 'true') {
						successMsg = `Connection between "${this.fromCompanyName}" and "${this.toCompanyName}" is now activated!`;
					} else {
						successMsg = `Connection between "${this.fromCompanyName}" and "${this.toCompanyName}" is now deactivated!`;
					}

					this.$toaster.success(successMsg);

					EventBus.$emit('onCloseSaveConnection', this.selConnection);
					this.$refs.modal.hide();

				} else {
					let errorMsg = '';
					if (this.toggleStatus(this.isActive) === 'true') {
						errorMsg = `There's a problem encountered during connection activation between "${this.fromCompanyName}" and "${this.toCompanyName}"`;
					} else {
						errorMsg = `There's a problem encountered during connection deactivation between "${this.fromCompanyName}" and "${this.toCompanyName}"`;
					}

					this.resetStatus();
					this.$toaster.error(errorMsg);
				}
			} catch (_error) {
				let errorMsg = '';
				if (this.toggleStatus(this.isActive) === 'true') {
					errorMsg = `There's a problem encountered during connection activation between "${this.fromCompanyName}" and "${this.toCompanyName}"`;
				} else {
					errorMsg = `There's a problem encountered during connection deactivation between "${this.fromCompanyName}" and "${this.toCompanyName}"`;
				}

				this.resetStatus();
				this.$toaster.error(errorMsg);
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelConnection');
	},
};
</script>
