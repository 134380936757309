<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-link"></i> Connection</b-card-title>
			<b-card-sub-title>Manages the connection betweeen companies within the supply chain</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Source">
											<v-select class="style-chooser" label="text" :options="companyOptions"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Status">
											<v-select class="style-chooser" label="text" :options="statusOptions"
												:reduce="(isActive) => isActive.value" v-model="filterBy.isActive">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item v-b-modal.add-connection v-show="!isViewer">
								Add Connection
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls"
									:name="fileName + '.xls'">
									Export Connections in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv"
									:name="fileName + '.csv'">
									Export Connections to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table ref="connectionsTable" show-empty striped hover :items="items" :fields="fields"
					:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>
					<template v-slot:cell(connections)="row">
						<span :key="connection" v-for="connection in row.item.connections">
							<span v-if="connection !== 'Others'">{{ connection }}</span>
							<span v-else>{{ connection }} - <i>{{ row.item.others }}</i></span>
							<br />
						</span>
					</template>

					<template v-slot:cell(noOfStorageLocations)="row">
						<span class="text-center numFont">{{ row.item.noOfStorageLocations }}</span>
					</template>

					<template v-slot:cell(actions)="row">
						<ConnectionRowActions :row="row" :isViewer="isViewer" :allCompaniesObj="allCompaniesObj" />
					</template>

					<template v-slot:cell(status)="row">
						<a @click="updateSelConnection(row.item)" v-if="!isViewer">
							<b-form-checkbox name="status" v-b-modal.activate-connection v-model="row.item.isActive"
								switch size="sm">
								{{ row.item.isActive === 'true' ? 'Active' : 'Inactive' }}
							</b-form-checkbox>
						</a>
						<span v-else>
							{{ row.item.isActive === 'true' ? 'Active' : 'Inactive' }}
						</span>
					</template>

					<template slot="row-details" slot-scope="row">
						<ConnectionDetailsView :row="row" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</div>
		</b-card>

		<!-- Modals here -->
		<AddConnection :allCompaniesObj="allCompaniesObj" :allStorageLocationsObj="allStorageLocationsObj"
			:connectionTypes="connectionTypes" />
		<EditConnection :allCompaniesObj="allCompaniesObj" :allStorageLocationsObj="allStorageLocationsObj"
			:connectionTypes="connectionTypes" />
		<ActivateConnection />
	</div>
</template>

<script>
// Components
import AddConnection from './connection/AddConnection';
import EditConnection from './connection/EditConnection';
import ActivateConnection from './connection/ActivateConnection';
import ConnectionDetailsView from './connection/ConnectionDetailsView';
import ConnectionRowActions from './connection/ConnectionRowActions';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { UserUtil } from '@/utils/userutil';

// DAO & API
import connectionDAO from '@/database/connections';
import connectionApi from '@/api/connectionApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'connection',
	components: {
		AddConnection,
		EditConnection,
		ActivateConnection,
		ConnectionDetailsView,
		ConnectionRowActions,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'companyName',
					label: 'Source',
					sortable: true,
				},
				{
					key: 'connectedCompanyName',
					label: 'Destination',
					sortable: true,
				},
				{
					key: 'connections',
					label: 'Connection Types'
				},
				{
					key: 'noOfStorageLocations',
					label: 'No of Storage Locations',
					sortable: true,
				},
				'status',
				{
					key: 'actions',
					thClass: 'text-center'
				}
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				company: { ...config.companyDefaultValue },
				isActive: 'true',
			},
			filterBy: {
				company: { ...config.companyDefaultValue },
				isActive: 'true',
			},
			prevFilter: {},

			companyOptions: [],
			statusOptions: config.statusOptionsWithDefault,
			connectionTypes: [],

			allCompaniesObj: {},
			allStorageLocationsObj: {},
			allConnectionsObj: {},
			allConnectionTypesObj: {},
			selConnection: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				'Source': 'company.name',
				'Destination': 'connectedCompany.name',
				'Connection Types': 'connections',
				'No of Storage Locations': 'noOfStorageLocations',
				'Storage Locations': 'Storage Locations',
				Status: 'status',
				Notes: 'notes',
				'Date Created': 'Date Created',
				'Created By': 'createdBy',
				'Date Updated': 'Date Updated',
				'Updated By': 'updatedBy',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Connection-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allStorageLocationsObj = { ...this.$store.getters.storageLocations };
				this.allConnectionsObj = { ...this.$store.getters.connections };

				this.companyOptions = DropDownItemsUtil.retrieveCompanyItems(this.allCompaniesObj);

				this.allConnectionTypesObj = await connectionDAO.getAllConnectionTypes();
				this.connectionTypes = DropDownItemsUtil.retrieveConnectionTypesItems(this.allConnectionTypesObj);

				this.filterTableContent();
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseSaveConnection', (connectionObj) => {
			this.updateTable(connectionObj);
		});
	},
	methods: {
		updateTable(connectionObj) {
			if (!_.isEmpty(connectionObj)) {
				// add fields for display
				connectionObj['companyName'] = connectionObj.company.name;
				connectionObj['connectedCompanyName'] =
					connectionObj.connectedCompany.name;

				this.allConnectionsObj[connectionObj.id] = connectionObj;
				this.$store.dispatch('updateAllConnections', this.allConnectionsObj);

				this.filterTableContent();
			}
		},
		filterTableContent() {
			let parentCompanyId = this.loggedUserCompany.parentCompanyId;
			let filteredObj = { ...this.allConnectionsObj };

			_.forEach(filteredObj, (con, conId) => {
				if (this.filterBy.company.id && this.filterBy.company.id !== con.companyId) {
					delete filteredObj[conId];
				} else {
					// filters the data retrieved from snapshot listener
					if (!this.isSuperAdmin && !UserUtil.hasCompanyAccess(this.allCompaniesObj, con.companyId)) {
						delete filteredObj[conId];
					}
					if (!this.isSuperAdmin && parentCompanyId && parentCompanyId.length !== 0 && con.companyId === parentCompanyId) {
						delete filteredObj[conId];
					}
				}

				if (this.filterBy.isActive !== con.isActive) {
					delete filteredObj[conId];
				}
			});

			this.processConnections(filteredObj);
		},
		processConnections(connections) {
			this.items = Object.values(connections);

			this.items.forEach((item) => {
				item['companyName'] = item.company.name;
				item['connectedCompanyName'] = item.connectedCompany.name;
				item['status'] = item.isActive === 'true' ? 'Active' : 'Inactive';
				item['noOfStorageLocations'] = this.getIncludedStorageLocations(item.storageLocations);
				item['Storage Locations'] = _.map(item.storageLocations, 'name');
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);

				// remove show details
				delete item._showDetails;
			});
			this.totalRows = this.items.length;

			// refresh table
			if (this.$refs.connectionsTable) {
				this.$refs.connectionsTable.refresh();
			}
		},

		async onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				await this.retrieveData();
				this.prevFilter = { ...this.filterBy };
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };
			}
		},
		async retrieveData() {
			try {
				// Show loader
				this.isLoading = true;

				let filter = { ...this.filterBy };
				filter.companyId = this.loggedUserCompany.id;

				if (this.isSuperAdmin) {
					const { data } = await connectionApi.getConnections(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allConnectionsObj = _.assign(this.allConnectionsObj, data.connections);
				} else {
					const { data } = await connectionApi.getConnections(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allConnectionsObj = _.assign(this.allConnectionsObj, data.connections);
				}

				this.filterTableContent();
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		updateSelConnection(item) {
			this.selConnection = item;
			this.$store.commit('SET_CURR_CONNECTION', item);
			EventBus.$emit('onUpdateSelConnection', item);
		},
		getIncludedStorageLocations(storageLocations) {
			let activeCount = 0;

			if (!_.isEmpty(storageLocations)) {
				let locArr = Object.values(storageLocations);
				locArr.forEach((loc) => {
					if (loc.isIncluded === 'true') {
						activeCount++;
					}
				});
			}

			return activeCount;
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
	beforeDestroy() {
		EventBus.$off('onCloseSaveConnection');
	},
};
</script>
